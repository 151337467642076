import { useLocation } from 'react-router-dom'
import { Navigate } from "react-router-dom";
import AppContext from '../Context/Context';


export default function RequireAuth({ children }) {
    const location = useLocation();
  
    return AppContext.IsUserLoggedIn() === true ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
  }


//   <RequireAuth><Settings /></RequireAuth> import RequireAuth from "./components/RequireAuth"