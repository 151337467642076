import React, { useEffect, useState } from "react";
import * as d3 from "d3";
type EcgProps = {
  BoxType: any;
  ECGTemplates: any;
  AllEctopies: any;
  mmvValueSplitView: any;
  TemplateVType: any;
  TemplateClass: any;
  templatesShowHide: any;
  SetBoxTypeName: Function;
  SetBoxType: Function;
  SetClickedBoxInfo: Function;
  SetTemplateVType: Function;
  SetTemplateClass: Function;
  showEctopyCharts: any;
};

function ECG(props: EcgProps) {
  useEffect(() => {
    if (props.ECGTemplates.length > 0) {
      loadGraphInBox(props.BoxType);
    }
  }, [props.ECGTemplates, props.BoxType]);

  function loadGraphInBox(p_VType: number) {
    for (let i = 0; i < props.ECGTemplates.length; i++) {
      if (props.ECGTemplates[i].VType === p_VType) {
        let BoxSize = 420;
        let BoxHeight = 100;

        let boxGraph = d3
          .select("#" + props.ECGTemplates[i].ECGTemplatesID)
          .attr("width", BoxSize)
          .attr("height", BoxHeight)
          .style("overflow", "hidden");

        // These will be the start and End time of the data in the box
        let startTime = 0;
        let endTime = 1;

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([80, BoxSize - 105]);

        let localYRange = 30000 / props.mmvValueSplitView;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([BoxHeight, 0]);

        let uniqueID = props.ECGTemplates[i].ECGTemplatesID;
        d3.selectAll("." + uniqueID).remove();

        if (props.ECGTemplates[i].Data.length > 0) {
          for (let j = 1; j < props.ECGTemplates[i].Data.length; j++) {
            boxGraph
              .append("line")
              .attr("class", uniqueID)
              .style("stroke", "green")
              .attr("x1", xScaleBox(props.ECGTemplates[i].Data[j - 1][0]))
              .attr("y1", yScaleBox(props.ECGTemplates[i].Data[j - 1][1]))
              .attr("x2", xScaleBox(props.ECGTemplates[i].Data[j][0]))
              .attr("y2", yScaleBox(props.ECGTemplates[i].Data[j][1]));
          }
        }

        boxGraph
          .append("text")
          .attr("class", uniqueID)
          .text(
            GetEctopiesCount(
              props.ECGTemplates[i].VType,
              props.ECGTemplates[i].Class
            ) +
              ", " +
              getPercentage(
                props.ECGTemplates[i].VType,
                props.ECGTemplates[i].Class
              )
          )
          .attr("fill", "black")
          .attr("font-size", "12px")
          .attr("font-weight", "bold")
          .attr("x", 15)
          .attr("y", 55);

        boxGraph
          .append("rect")
          .attr("class", uniqueID)
          .attr("x", 320)
          .attr("y", 38)
          .attr("width", 90)
          .attr("height", 25)
          .attr("fill", "#daefff")
          .attr("rx", 13)
          .attr("ry", 13);

        boxGraph
          .append("text")
          .attr("class", uniqueID)
          .text("class " + props.ECGTemplates[i].Class)
          .attr("fill", "black")
          .attr("font-size", "12px")
          .attr("font-weight", "bold")
          .attr("text-anchor", "end")
          .attr("x", 388)
          .attr("y", 55);
      }
    }
  }

  function GetEctopiesCount(p_intType: number, p_intClass: number) {
    var l_count = 0;
    for (let i = 0; i < props.AllEctopies.length; i++) {
      if (
        props.AllEctopies[i].VType === p_intType &&
        props.AllEctopies[i].Class === p_intClass
      ) {
        l_count++;
      }
    }
    return l_count;
  }

  function getPercentage(p_intType: number, p_intClass: number) {
    var l_sum = 0;
    var l_count = 0;
    for (let i = 0; i < props.AllEctopies.length; i++) {
      if (
        props.AllEctopies[i].VType === p_intType &&
        props.AllEctopies[i].Class === p_intClass
      ) {
        l_count++;
      }
    }

    for (let i = 0; i < props.AllEctopies.length; i++) {
      if (props.AllEctopies[i].VType === p_intType) {
        l_sum++;
      }
    }

    return Math.floor((l_count / l_sum) * 100).toFixed(1) + "%";
  }

  function BoxClicked(
    p_intType: any,
    p_Boxinfo: any,
    p_Boxinfo1: any,
    p_intVType: number,
    p_intClass: number
  ) {
    if (p_intType === 1) {
      props.SetBoxTypeName("VE");
    } else if (p_intType === 2) {
      props.SetBoxTypeName("SVE");
    }

    props.SetBoxType(p_intType);
    props.SetClickedBoxInfo(p_Boxinfo + " , " + p_Boxinfo1);
    props.SetTemplateVType(p_intVType);
    props.SetTemplateClass(p_intClass);
  }

  useEffect(() => {
    if (props.TemplateVType > 0 && props.TemplateClass > 0) {
      BoxClicked(
        props.TemplateVType,
        GetEctopiesCount(props.TemplateVType, props.TemplateClass),
        getPercentage(props.TemplateVType, props.TemplateClass),
        props.TemplateVType,
        props.TemplateClass
      );
    }
  }, [props.templatesShowHide, props.ECGTemplates, props.AllEctopies]);

  return (
    <div className="template--classes">
      {props.ECGTemplates.map((Item: any) => (
        <React.Fragment key={Item.ECGTemplatesID + 1}>
          <>
            {Item.VType === props.BoxType && (
              <div
                className={`${
                  Item.Class === props.TemplateClass &&
                  Item.VType === props.TemplateVType &&
                  props.showEctopyCharts === true
                    ? "template--class--item template--class--item__selected"
                    : "template--class--item"
                }`}
                onClick={() => {
                  BoxClicked(
                    Item.VType,
                    GetEctopiesCount(Item.VType, Item.Class),
                    getPercentage(Item.VType, Item.Class),
                    Item.VType,
                    Item.Class
                  );
                }}
              >
                <svg id={Item.ECGTemplatesID}></svg>
              </div>
            )}
          </>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ECG;
