export const eventMap = {
  SR: "SR",
  SB: "SB",
  ST: "ST",
  AF: "AF",
  ISVE: "SV",
  VE: "VE",
  NOISE: "NO",
  MAX_HR: "MAX_HR",
  MIN_HR: "MIN_HR",
};
