import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
type ProgressBarProps = {
  progressBarCoutDown: number;
};
function ProgressBarAnimation(props: ProgressBarProps) {

    useEffect(() => {


      }, [props.progressBarCoutDown]);

  return (
    <>
      {props.progressBarCoutDown > 0  && (
        <div className="progress-run">
          <ProgressBar
            now={props.progressBarCoutDown}
            label={`${props.progressBarCoutDown}%`}
          />
        </div>
      )}
    </>
  );
}

export default ProgressBarAnimation;
