import classnames from 'classnames'

export default function Box({
  height = 'h-full',
  width = 'w-1/3',
  children,
  className,
  px = 'px-14',
  pt = 'pt-[32px]',
  pb = 'pb-14',
}) {
  return (
    <div
      className={classnames(
        className,
        'border-[1px] border-[#E8E8E8]   shadow-[0px_20px_40p_rgba(121,121,121,0.06)] bg-white rounded-[20px]  ',
        height,
        width,
        px,
        pt,
        pb,
      )}
    >
      {children}
    </div>
  )
}
