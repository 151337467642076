import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import * as Utility from "../Shared/Utility";

type HRTrendBarsProps = {
  hrTrendsShowHide: boolean;
  mmsValueSplitView: any;
  globalW: any;
  SelectedDataSet: any;
  DataStartDate: Date;
  DataEndDate: Date;
  handleMoveGraph: Function;
  getAllBPMFromDataSet: Function;
  setintervalSplitView: Function;
  SelectedTime: any;
  lastClick: any;
  setlastClick: Function;
  AppArea: any;
};

function HRTrendBarsView(props: HRTrendBarsProps) {
  const svgHRTrendBarsRef = useRef<any>();

  const [drawAgain, setDrawAgain] = useState(false);
  const [clickPositionX, setclickPositionX] = useState(-1);

  let svgLeft = 0;
  let svgTop = 0;
  let gridWidth = 0;
  let range = 0;
  let margin = { top: 10, right: 40, bottom: 25, left: 30 };

  function getSVGx(x: any) {
    return x - svgLeft;
  }

  function getSVGy(y: any) {
    return y - svgTop;
  }

  function getTimeFromXPixel(x: any) {
    let graphX = x - margin.left;
    let newStartTime = Math.floor((graphX / gridWidth) * range) * 60;
    return newStartTime + 30;
  }

  function getIndexFromXPixel(x: any) {
    let graphX = x - margin.left;
    let index = Math.floor((graphX / gridWidth) * range);
    if (index >= 0 && index < range) {
      return index;
    }
    return -1;
  }

  function getXPixelFromTime(inputTime: any) {
    let x = 0;
    let timeInMinutes = Math.floor(inputTime / 60);
    x = (timeInMinutes / range) * gridWidth;

    // Adjust for Bar Width and padding
    let barWidth = gridWidth / range;

    return x + margin.left + barWidth / 2;
  }

  const MouseDown = (e: any) => {
    let localX = getSVGx(e.pageX);
    setclickPositionX(localX);
    let newStartTime = getTimeFromXPixel(localX);
    props.handleMoveGraph(newStartTime, 0);
    props.setlastClick(props.AppArea.HRTrendBars);
  };

  // This is for drawing the HRTrends view
  useEffect(() => {
    if (props.hrTrendsShowHide === true) {
      let allMinutesHR = props.getAllBPMFromDataSet(false) as any;

      //var BottomDivWidth =
      //  document.getElementById("MainBottomDiv")?.clientWidth;
      let w = props.globalW - 40;
      let heightOfHRTrendView = 220;
      let graphHeight = heightOfHRTrendView - (margin.top + margin.bottom);
      let maxHR = 200;
      // if (BottomDivWidth) {
      //   w = BottomDivWidth - 290;
      // }
      gridWidth = w - (margin.left + margin.right);

      let localIntervalSplitView = w / (props.mmsValueSplitView * 5);
      props.setintervalSplitView(localIntervalSplitView);

      let svgHRTrend = d3
        .select(svgHRTrendBarsRef.current)
        .attr("width", w) //globalW
        .attr("height", heightOfHRTrendView)
        .on("mousedown", MouseDown);
      //        .style("overflow", "hidden");

      let boundingRect: any = document
        .getElementById("hrTrendBarsSVG")
        ?.getBoundingClientRect();

      svgLeft = boundingRect.left;
      svgTop = boundingRect.top;

      range = allMinutesHR.length;

      let xScaleHRTrendView = d3
        .scaleBand()
        .domain(allMinutesHR.map((d: any, i: any) => i))
        .range([margin.left, w - margin.right])
        .padding(0.2);

      let yScaleHRTrendView = d3
        .scaleLinear()
        .domain([0, maxHR])
        .range([heightOfHRTrendView - margin.bottom, margin.top]);

      let StartDateTime = new Date(props.DataStartDate);
      //   console.log("Start Before adding : " + StartDateTime);
      StartDateTime.setSeconds(0);
      //     console.log("Start after adding : " + StartDateTime);
      let EndDateTime = new Date(props.DataEndDate);
      //      console.log("End Before adding : " + EndDateTime);
      EndDateTime.setMinutes(allMinutesHR.length);
      //      console.log("End after adding : " + EndDateTime);

      let xScaleTime = d3
        .scaleTime()
        .domain([StartDateTime, EndDateTime])
        .range([margin.left, w - margin.right]);

      d3.selectAll(".hrTrendBarsView").remove();
      d3.selectAll("#hrTrendBarsViewLeftAxis").remove();
      d3.selectAll("#hrTrendBarsViewRightAxis").remove();
      d3.selectAll("#hrTrendBarsViewBottomAxis").remove();

      svgHRTrend
        .append("g")
        .attr("id", "hrTrendBarsViewLeftAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScaleHRTrendView));

      svgHRTrend
        .append("g")
        .attr("id", "hrTrendBarsViewRightAxis")
        .attr("transform", `translate(${w + -margin.right},0)`)
        .call(d3.axisRight(yScaleHRTrendView));

      svgHRTrend
        .append("g")
        .attr("id", "hrTrendBarsViewBottomAxis")
        .attr(
          "transform",
          `translate(0,${heightOfHRTrendView + -margin.bottom})`
        )
        .call(d3.axisBottom(xScaleTime));

      d3.selectAll(".gridlineHRTrendBars").remove();
      // add the gridlines
      svgHRTrend
        .selectAll(".gridlineHRTrendBars")
        .data(yScaleHRTrendView.ticks())
        .enter()
        .append("line")
        .attr("class", "gridlineHRTrendBars")
        .attr("x1", margin.left)
        .attr("y1", (d: number) => yScaleHRTrendView(d))
        .attr("x2", margin.left + gridWidth)
        .attr("y2", (d: number) => yScaleHRTrendView(d))
        .attr("stroke", "#EEE");

      if (allMinutesHR && allMinutesHR.length > 1) {
        // var curveFunc = d3
        //   .area()
        //   .x(function (d: any, i: any) {
        //     return xScaleHRTrendView(i);
        //   }) // Position of both line breaks on the X axis
        //   .y1(function (d: any, i: any) {
        //     return yScaleHRTrendView(d.bpm);
        //   }) // Y position of top line breaks
        //   .y0(yScaleHRTrendView(0));

        // svgHRTrend
        //   .append("path")
        //   .attr("d", curveFunc(allMinutesHR))
        //   .attr("class", "hrTrendBarsView")
        //   .attr("stroke", "rgba(148, 169, 180,0.8)")
        //   .attr("fill", "rgba(148, 169, 180,0.8)");

        // Draw the bars
        svgHRTrend
          .selectAll("rect")
          .raise()
          .data(allMinutesHR)
          .enter()
          .append("rect")
          .attr("x", (d: any, i: any) => xScaleHRTrendView(i))
          .attr("y", (d: any) => yScaleHRTrendView(d.bpm) + margin.top)
          .attr("width", xScaleHRTrendView.bandwidth())
          .attr("height", (d: any) => graphHeight - yScaleHRTrendView(d.bpm))
          .attr("fill", "steelblue");

        // Add the rectangle to the SVG

        let rectWidth = 25;
        let rectHeight = 25;

        let rectX = margin.left + 20;
        let rectY = margin.top;

        svgHRTrend
          .append("rect")
          .attr("x", rectX)
          .attr("y", rectY)
          .attr("class", "hrTrendBarsView")
          .attr("width", rectWidth)
          .attr("fill", "#60BAF1")
          .attr("height", rectHeight)
          .style("stroke", "#888")
          .style("stroke-width", "2px")
          .attr("rx", "3");

        // Add text to the rectangle
        svgHRTrend
          .append("text")
          .text("All")
          .attr("class", "hrTrendBarsView")
          .attr("x", rectX + rectWidth / 2)
          .attr("y", rectY + rectHeight / 2)
          .attr("text-anchor", "middle")
          .attr("fill", "#555")
          .attr("font-size", "12px")
          .attr("dominant-baseline", "middle");

        // Add text to the rectangle

        for (let i = 0; i < props.SelectedDataSet.length; i++) {
          let margin = 35;

          svgHRTrend
            .append("rect")
            .attr("class", "hrTrendBarsView")
            .attr("x", rectX + margin * (i + 1))
            .attr("y", rectY)
            .attr("width", rectWidth)
            .attr("fill", "#ccc")
            .attr("height", rectHeight)
            .style("stroke", "#888")
            .style("stroke-width", "2px")
            .attr("rx", "3");

          svgHRTrend
            .append("text")
            .text(i + 1)
            .attr("class", "hrTrendBarsView")
            .attr("x", rectX + rectWidth / 2 + margin * (i + 1))
            .attr("y", rectY + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("fill", "#555")
            .attr("font-size", "12px")
            .attr("dominant-baseline", "middle");
        }
      }

      d3.selectAll(".clickHRTrendBars").remove();
      d3.selectAll(".clickHRTrendBarsExternal").remove();
      //console.log("b clickPositionX : " + clickPositionX);
      if (props.lastClick === props.AppArea.HRTrendBars && clickPositionX > 0) {
        //console.log("clickPositionX : " + clickPositionX);
        svgHRTrend
          .append("rect")
          .attr("class", "clickHRTrendBars")
          .attr("x", clickPositionX - 1)
          .attr("y", margin.top + 15)
          .attr("width", 2)
          .attr(
            "height",
            heightOfHRTrendView - (margin.top + margin.bottom + 15)
          )
          .attr("fill", "rgba(70, 130, 180,0.6)");

        let index = getIndexFromXPixel(clickPositionX);

        if (index >= 0) {
          svgHRTrend
            .append("text")
            .attr("class", "clickHRTrendBars")
            .text(Math.round(allMinutesHR[index].bpm) + " bpm")
            .attr("fill", "#666666")
            .attr("font-size", "11px")
            .attr("x", clickPositionX - 16)
            .attr("y", 20);
        }
      } else {
        //      console.log(Utility.ConvertTimeFormatIntoSeconds(props.SelectedTime));
        let timeInSeconds = Utility.ConvertTimeFormatIntoSeconds(
          props.SelectedTime
        );
        if (timeInSeconds > 0) {
          //console.log("clickPositionX : " + clickPositionX);
          // getXPixelFromTime(props.SelectedTime)
          svgHRTrend
            .append("rect")
            .attr("class", "clickHRTrendBarsExternal")
            .attr("x", getXPixelFromTime(timeInSeconds))
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfHRTrendView - (margin.top + margin.bottom))
            .attr("fill", "rgba(70, 130, 180,0.6)");
        }
      }
    }
    setDrawAgain(true);
  }, [props.hrTrendsShowHide, drawAgain, clickPositionX, props.SelectedTime,props.globalW]);

  return (
    <div className="ecg--trends__right">
      <svg id="hrTrendBarsSVG" ref={svgHRTrendBarsRef}></svg>
    </div>
  );
}

export default HRTrendBarsView;
