export const notificationData = [
  {
    rhythm: 'Artial Rhythms',
    inboxReportable: 'Yes(1 Baseline)',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Baseline',
    inboxReportable: 'Yes(Symptomatic)',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Patient Initiated Trigger',
    inboxReportable: 'Yes(Report First Time)',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Paced Rhythms',
    inboxReportable: 'N/A',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Sinus Rhythm 60-100 bpm',
    inboxReportable: '0 O',
    urgentNotification: '0 O',
    emgentNotification: '0 O',
  },
  {
    rhythm: 'Sinus Rhythm with 1D',
    inboxReportable: 'N/A',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Sinus Rhythm with IVCD',
    inboxReportable: 'N/A',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Sinus Rhythm with Long QT',
    inboxReportable: 'N/A',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Sinus Rhythm with WPW',
    inboxReportable: 'N/A',
    urgentNotification: 'N/A',
    emgentNotification: 'N/A',
  },
  {
    rhythm: 'Sinus Arrhythmia Irregular 60-100 bpm',
    inboxReportable: '<40 bpm >6 sec',
    urgentNotification: '<30 bpm >10 sec',
    emgentNotification: '<30 bpm >30 sec',
  },
  {
    rhythm: 'Sinus Bradycardia =<59 bpm bpm',
    inboxReportable: '>150 bpm and > 6 sec',
    urgentNotification: '>150 bpm and > 30 sec',
    emgentNotification: '<180 bpm >30 sec',
  },
  {
    rhythm: 'Atrial Tachycardia/ATV',
    inboxReportable: '>6 beats and > 101 bpm',
    urgentNotification: '>160 bpm and > 30 sec',
    emgentNotification: '<180 bpm >30 sec',
  },
]
