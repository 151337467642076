import cardiacRhythmImg from '../images/cardiac-rhythm.png'
//import Image from 'next/image'
import ReportInfoBox from './ReportInfoBox'

export default function ReportHeader({
  patientInfo = 'Robert Downey/M/12/02/1985',
  startDate = ' 01/10/2019 ',
  boxInfo = 'Heart RateDistribution',
  boxInfoBg = '#CEEBEE',
}) {
  return (
    <div className="mt-24 mb-14">
      <section className="flex justify-between items-end mb-6">
        {/* <div className="h-5 w-[220px] 2xl:h-[93px] 2xl:w-[342px]"> */}
        <div className="h-[93px] w-[342px]">
          <img src={cardiacRhythmImg} layout="responsive" />
        </div>
        <div className="text-2xl  text-center ">{patientInfo}</div>
        <div>
          <ReportInfoBox
            background={boxInfoBg}
            className="text-xl"
            info={boxInfo}
          />
          <p className="mt-4 text-2xl">
            <span>Start Date:</span> <span>{startDate}</span>
          </p>
        </div>
      </section>
      {/* <div className="bg-report-skyblue w-full h-[2px] " /> */}
    </div>
  )
}
