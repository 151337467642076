import { createContext } from "react";

const AppContext = createContext(null) as any;

AppContext.UserName = "admin";
AppContext.Password = "pass81912";
AppContext.Token = "tknlgusr";

AppContext.GraphColors = {
  DefaultLineColor: "green",
  VELineColor: "#c11401",
  SVELineColor: "#f79a00",
};

AppContext.view = {
  Patient: 0,
  Analysis: 1,
  Events: 2,
  Strips: 3,
  Templates: 5,
  Reports: 4,
};

//AppContext.SetCookie("Token", response.data.Data[0].Token, 30);

AppContext.SetCookie = (cname: any, cvalue: any, exmins: any) => {
  const d = new Date();
  if (exmins == 0) {
    d.setTime(0);
  } else {
    d.setTime(d.getTime() + exmins * 60 * 1000);
  }
  let expires = "expires=" + d;
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

AppContext.GetCookie = (cname: any) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

AppContext.IsUserLoggedIn = () => {
  var token = AppContext.GetCookie(AppContext.Token);
  if (token && token !== "") {
    return true;
  } else {
    return false;
  }
};

AppContext.GenerateUniqueGUID = () => {
  return (
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }) +
    "-" +
    Date.now()
  );
};

export default AppContext;
