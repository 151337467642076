import { ECGClassification } from "./Utility";

// Start of Preprocessing code
export function preprocessECG(
  ecgData: number[][],
  samplingRate: number
): number[][] {
  // Step 1: Remove baseline wander using high-pass filtering
  const cutoffFreq = 0.5; // Adjust the cutoff frequency as needed
  const filteredData = highPassFilter(ecgData, samplingRate, cutoffFreq);

  // SKIPPING Step 2 for now
  // Step 2: Baseline correction using the median filter
  /*  const medianFilterWindow = 0.2; // Adjust the window size as needed
  const baselineCorrectedData = medianFilter(
    filteredData,
    samplingRate,
    medianFilterWindow
  );
*/

  // Using instead filteredData of baselineCorrectedData in the following

  // Step 3: Remove noise and artifacts using a band-stop filter
  const notchFilterFrequency = 30; // Adjust the notch filter frequency as needed
  const notchFilterBandwidth = 5; // Adjust the notch filter bandwidth as needed
  const denoisedData = notchFilter(
    filteredData,
    samplingRate,
    notchFilterFrequency,
    notchFilterBandwidth
  );

  // Step 4: Additional preprocessing steps (if needed)
  // Add your additional preprocessing code here

  return denoisedData;
}

function highPassFilter(
  data: number[][],
  samplingRate: number,
  cutoffFreq: number
): number[] {
  const RC = 1.0 / (2.0 * Math.PI * cutoffFreq);
  const dt = 1.0 / samplingRate;
  const alpha = RC / (RC + dt);

  const filteredData: number[] = [];
  if (data.length > 0) {
    if (data[0].length > 0) {
      let prevOutput = data[0][0];
      let prevValue = data[0][0];

      for (let i = 0; i < data.length; i++) {
        let startIndex = 0;
        if (i === 0) {
          startIndex = 1;
          // Push a 0 instead of the first value
          filteredData.push(0);
        }
        for (let j = startIndex; j < data[i].length; j++) {
          const input = data[i][j];
          const output = alpha * (prevOutput + input - prevValue);
          filteredData.push(output);
          prevValue = data[i][j];
          prevOutput = output;
        }
      }
    }
  }

  return filteredData;
}

function medianFilter(
  data: number[],
  samplingRate: number,
  windowSize: number
): number[] {
  const filteredData: number[] = [];
  const halfWindowSize = Math.floor((windowSize * samplingRate) / 2);

  for (let i = 0; i < data.length; i++) {
    const start = Math.max(0, i - halfWindowSize);
    const end = Math.min(data.length - 1, i + halfWindowSize);

    const window = data.slice(start, end + 1);
    const sortedWindow = window.sort();
    const medianIndex = Math.floor(sortedWindow.length / 2);
    const medianValue = sortedWindow[medianIndex];

    filteredData.push(medianValue);
  }

  return filteredData;
}

function notchFilter(
  data: number[],
  samplingRate: number,
  notchFreq: number,
  bandwidth: number
): number[][] {
  var filteredData: number[] = [];
  const returnData: number[][] = [];
  const combinedData: number[] = [];
  const notchFreqNorm = notchFreq / (samplingRate / 2);
  const bandwidthNorm = bandwidth / (samplingRate / 2);

  const a1 = Math.exp(-2.0 * Math.PI * bandwidthNorm);
  const b0 = 1.0;
  const b1 = -2.0 * Math.cos(2.0 * Math.PI * notchFreqNorm);
  const b2 = 1.0;

  let prevX1 = 0;
  let prevX2 = 0;
  let prevY1 = 0;
  let prevY2 = 0;

  var count = 0;
  for (let i = 0; i < data.length; i++) {
    const input = data[i];

    const output =
      (b0 * input +
        b1 * prevX1 +
        b2 * prevX2 -
        a1 * prevY1 -
        a1 * a1 * prevY2) /
      (1 + a1 + a1 * a1);

    filteredData.push(Math.round(output));
    count++;

    if (count === 125) {
      count = 0;
      returnData.push(filteredData);

      // Append the contents of filtered Data in combined Data
      for (let index = 0; index < filteredData.length; index++) {
        combinedData.push(filteredData[index]);
      }

      filteredData = [];
    }
    prevX2 = prevX1;
    prevX1 = input;
    prevY2 = prevY1;
    prevY1 = output;
  }

  // Return both returnData and CombinedData

  let returnObject = {} as any;
  returnObject.returnData = returnData;
  returnObject.combinedData = combinedData;

  return returnObject;
}

// End of Preprocessing code

export function getEDFDataIndexesFromTime(inputTime: number): any[] {
  // StartTime is the startTime of the EDF data - for now, assuming that its 0 as in midnight
  let startTime = 0;
  // numberOfSamples is the number of samples in the EDF data - available in header
  let numberOfSamples = 125;
  let outerIndex = 0;
  let innerIndex = 0;

  let flooredTime = Math.floor(inputTime);
  outerIndex = flooredTime;

  let remainder = inputTime - flooredTime;
  innerIndex = Math.floor(remainder * numberOfSamples);
  // console.log(
  //   "Input: " + inputTime + ", outer: " + outerIndex + ", inner : " + innerIndex
  // );
  return [outerIndex, innerIndex];
}

interface EDFDataPeak {
  outerIndex: number;
  innerIndex: number;
  peakTime: number;
  rTime: number;
  value: number;
  //pWaveIndex: number;
  qWaveIndex: number;
  sWaveIndex: number;
  isSmooth: boolean;
}

export const PwaveEnum = {
  Present: 1,
  Absent: 2,
  Absent_inverted_retrograde: 3,
  Two_p_wave_with_one_QRS: 4,
  P_P_regular: 5,
  The_AV_block_which_does_not_match_the_above_criteria: 6,
  Morphology_change_with_premature_Notched_P_wave: 7,
  Morphology_change_with_premature: 8,
  saw_tooth: 9,
  No_identifiable_P_wave: 10,
  Multiple_p_wave: 11,
  Different: 12,
};

// This peak will be discarded
interface Peak {
  index: number;
  value: number;
}

function evaluateMorphology(ecgData: any, maxPeak: EDFDataPeak): boolean {
  const windowSize = 5; // Number of samples to consider on each side of the peak
  const threshold = 0.2; // Threshold for determining the sharpness of the peak

  let signalIndex = 0;
  let numberOfSamples = 125;

  let leftInnerIndex = maxPeak.innerIndex - windowSize;
  let leftOuterIndex = maxPeak.outerIndex;
  if (leftInnerIndex < 0) {
    leftOuterIndex--;
    if (leftOuterIndex < 0) {
      leftOuterIndex = 0;
      leftInnerIndex = 0;
    } else {
      leftInnerIndex = numberOfSamples + leftInnerIndex;
    }
  }
  let rightInnerIndex = maxPeak.innerIndex + windowSize;
  let rightOuterIndex = maxPeak.outerIndex;
  if (rightInnerIndex >= numberOfSamples) {
    rightOuterIndex++;
    if (rightOuterIndex >= ecgData._physicalSignals[signalIndex].length) {
      rightOuterIndex = ecgData._physicalSignals[signalIndex].length - 1;
      rightInnerIndex =
        ecgData._physicalSignals[signalIndex][rightOuterIndex].length - 1;
    } else {
      rightInnerIndex = rightInnerIndex - numberOfSamples;
    }
  }

  let sharpnessCount = 0;
  let smoothnessCount = 0;

  // console.log("ecgdata");
  // console.log(ecgData);
  // console.log(
  //   "left outer: " + leftOuterIndex + ", rightouter : " + rightOuterIndex
  // );
  // console.log(
  //   "left inner: " + leftInnerIndex + ", rightinner : " + rightInnerIndex
  // );

  for (let i = leftOuterIndex; i <= rightOuterIndex; i++) {
    let thisStartIndex = leftInnerIndex;
    if (leftOuterIndex < rightOuterIndex && i > leftOuterIndex) {
      thisStartIndex = 0;
    }
    let thisEndIndex = rightInnerIndex;
    if (leftOuterIndex < rightOuterIndex && i < rightOuterIndex) {
      thisEndIndex = numberOfSamples - 1;
    }

    for (let j = thisStartIndex; j <= thisEndIndex; j++) {
      if (i === maxPeak.outerIndex && j === maxPeak.innerIndex) {
        continue; // Skip the peak index itself
      }

      let currentSample = 0;
      let previousSample = 0;
      try {
        currentSample = ecgData._physicalSignals[signalIndex][i][j];
      } catch (error) {
        console.log("crashing at :" + i + "," + j);
      }

      previousSample = currentSample;

      if (j - 1 < 0) {
        // Check if the previous outerIndex exists then pick up the last entry from there
        if (i - 1 >= 0) {
          previousSample =
            ecgData._physicalSignals[signalIndex][i - 1][numberOfSamples - 1];
        }
      } else {
        previousSample = ecgData._physicalSignals[signalIndex][i][j - 1];
      }

      let nextSample = currentSample;
      if (j + 1 >= numberOfSamples) {
        // Check if next outerIndex exists then pick up the first entry from there
        if (i + 1 < ecgData._physicalSignals[signalIndex].length) {
          previousSample = ecgData._physicalSignals[signalIndex][i + 1][0];
        }
      } else {
        nextSample = ecgData._physicalSignals[signalIndex][i][j + 1];
      }

      if (currentSample > previousSample && currentSample > nextSample) {
        sharpnessCount++;
      } else {
        smoothnessCount++;
      }
    }
  }

  const sharpnessRatio = sharpnessCount / (sharpnessCount + smoothnessCount);
  // if (sharpnessRatio >= threshold) {
  //   console.log("sharpnessRatio : " + sharpnessRatio);
  // }
  if (sharpnessRatio >= threshold) {
    return false; // "sharp";
  } else {
    return true; // "smooth";
  }
}

// Usage example
//const ecgData: number[] = [
/* ECG data array */
//];
const rPeaks: Peak[] = [
  /* R wave peaks array */
];

//const pWaveIndices = detectPWave(ecgData, rPeaks);
//console.log("Detected P wave indices:", pWaveIndices);

export function detectPeaksInEDFData(
  decodedEDFData: any,
  threshold: number,
  windowSize: number
): any[] {
  const peaks: any = [];

  //  let Signal = 0;
  let numberOfDataRecords = decodedEDFData._header.nbDataRecords; // 3864;
  let numberOfSignals = decodedEDFData._header.nbSignals; //3;
  let signalIndex = 0;

  // The time when the data starts in seconds from 00:00
  let dataStartSeconds = 0;
  let numberOfSamples =
    decodedEDFData._header.signalInfo[signalIndex].nbOfSamples; //125;
  let secondsPerIndex = 1 / numberOfSamples;

  var slidingWindow = [];
  const doubleWindowSize = windowSize * 2;

  if (numberOfDataRecords > 0) {
    if (decodedEDFData._physicalSignals[signalIndex][0].length > windowSize) {
      for (let i = 0; i < windowSize; i++) {
        slidingWindow.push(decodedEDFData._physicalSignals[signalIndex][0][i]);
      }
    }
  }
  // TODO: Check if data is empty, include a fix

  for (let j = 0; j < numberOfDataRecords; j++) {
    let timeOfThisRowInSeconds = j + dataStartSeconds;
    for (
      let k = 0;
      k < decodedEDFData._physicalSignals[signalIndex][j].length;
      k++
    ) {
      let subSecondTimeOfThisRecord = k * secondsPerIndex;
      let fullTimeOfThisRecord =
        timeOfThisRowInSeconds + subSecondTimeOfThisRecord;

      var outerIndex = j;
      var innerIndex = k + windowSize;
      if (
        innerIndex >= decodedEDFData._physicalSignals[signalIndex][j].length
      ) {
        outerIndex += 1;
        innerIndex =
          innerIndex - decodedEDFData._physicalSignals[signalIndex][j].length;
      }

      if (outerIndex < numberOfDataRecords) {
        slidingWindow.push(
          decodedEDFData._physicalSignals[signalIndex][outerIndex][innerIndex]
        );
      }

      // console.log(
      //   "A1:" +
      //     decodedEDFData._physicalSignals[signalIndex][j][k] +
      //     ",2:" +
      //     slidingWindow[windowSize - 1]
      // );

      //        slidingWindow.push(decodedEDFData._physicalSignals[signalIndex][j][k]);
      if (slidingWindow.length >= doubleWindowSize) {
        // Check
        //const max = Math.max(...slidingWindow.map((obj) => obj));

        // console.log(
        //   "B1:" +
        //     decodedEDFData._physicalSignals[signalIndex][j][k] +
        //     ",2:" +
        //     slidingWindow[windowSize - 1]
        // );

        var max = -100000;
        var maxIndex = -1;
        var sum = 0;
        for (let m = 0; m < slidingWindow.length; m++) {
          sum += slidingWindow[m];
          if (slidingWindow[m] > max) {
            max = slidingWindow[m];
            maxIndex = m;
          }
        }

        var average = sum / slidingWindow.length;

        var thresholdAdjustment = 0;
        // if (average > 200) {
        //   thresholdAdjustment = average - 200;
        // }
        // if (average < -200) {
        //   thresholdAdjustment = average + 200;
        // }
        // Our record is at windowSize
        if (
          max >= threshold + thresholdAdjustment &&
          maxIndex === windowSize - 1
        ) {
          let l_singleRowData = [
            fullTimeOfThisRecord,
            decodedEDFData._physicalSignals[signalIndex][j][k],
          ];
          peaks.push(l_singleRowData);
          //    console.log(average);

          //            console.log(l_singleRowData);
          //            console.log(slidingWindow);
        }

        // Pop first record
        slidingWindow.shift();
      }
    }
  }

  // for (let i = windowSize; i < data.length - windowSize; i++) {
  //   const segment = data.slice(i - windowSize, i + windowSize + 1);
  //   const max = Math.max(...segment.map((obj) => obj[1]));

  //   if (max >= threshold && max === data[i][1]) {
  //     peaks.push(i);
  //   }
  // }
  console.log("Peaks");
  console.log(peaks);
  return peaks;
}
