import classnames from 'classnames'

export default function Container({
  className,
  px = 'px-7',
  children,
  ...props
}) {
  return (
    <div
      {...props}
      className={classnames(
        'w-full bg-white  border  rounded-[10px] shadow-[0px_12px_26px_rgba(0,0,0,0.06)] ',
        className,
        px,
      )}
    >
      {children}
    </div>
  )
}
