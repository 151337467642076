import classNames from 'classnames'

export default function ReportInfoBox({
  width = 267,
  height = 149,
  background = '#CEEBEE',
  className,
  children,
  info,
}) {
  return (
    <div
      style={{ width: width, height: height, background: background }}
      className={classNames(
        'border-t-[5px] border-black border-b-[5px]  p-4',
        className,
      )}
    >
      {children || info}
    </div>
  )
}
