/** @format */

//import PatientReportLayout from '../PatientReportLayout'
import classnames from "classnames";
import { notificationData } from "./data";

export default function NotificationCriteriaPage() {
  const talbeHeadingStyle = "font-bold text-lg";
  const tdStyle = " text-xl font-semibold";
  return (
    //<PatientReportLayout>
    <>
      <div
        className="mt-3  w-full  bg-white rounded
shadow-[0px_20px_40px_rgba(121,121,121,0.06)]  "
      >
        <div className="border-b-[1px] border-[#E9E9E9] h-[51px] pl-[77px] py-[13px]">
          <h2 className="font-bold text-xl mb-5">
            Notification Protocol Amendments
          </h2>
        </div>
        <div className="pb-[98px]  pt-[28px]  pr-[164px] pl-[77px]  max-h-fit">
          <div className=" h-full  w-full  ">
            {/* table headings */}
            <div className=" gap-8  justify-items-start grid grid-cols-4  mb-4    ">
              <th className={talbeHeadingStyle}>Events</th>
              <th className={classnames(talbeHeadingStyle, "w-[189px]")}>
                {/* Inbox Reportable (Weekly Summary) */}
                Rate
              </th>
              <th className={classnames(talbeHeadingStyle, "w-[189px]")}>
                {/* Urgent Notification (Office Hour) */}
                Duration
              </th>
              <th className={classnames(talbeHeadingStyle, "w-[196px]")}>
                {/* Emgent Notification (Immediate) */}
                Status
              </th>
            </div>
            {/* table data  */}
            <div className="flex flex-col gap-4">
              {notificationData.map((data, index) => (
                <div
                  key={index}
                  className=" gap-16  justify-items-start grid grid-cols-4"
                >
                  <td className={classnames(tdStyle)}>{data.rhythm}</td>
                  <td className={classnames(tdStyle)}>
                    {data.inboxReportable}
                  </td>
                  <td className={classnames(tdStyle)}>
                    {data.urgentNotification}
                  </td>
                  <td className={classnames(tdStyle)}>
                    {/* {data.emgentNotification} */}

                    {data.status == "no" ? (
                      <div class="h-6 w-6 flex items-center justify-center text-white  rounded-md transition-all   bg-red-500 cursor-pointer  cursor-pointer">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          class="text-2xl"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
                        </svg>
                      </div>
                    ) : (
                      <div class="h-6 w-6 flex items-center justify-center text-white  rounded-md transition-all  bg-green-400 cursor-pointer">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                        </svg>
                      </div>
                    )}
                  </td>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
    //</PatientReportLayout>
  );
}
