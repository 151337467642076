//import Image from 'next/image'
import reportsImg from '../../reports.png'
import cardiacRhythmImg from '../../images/cardiac-rhythm.png'
import hospitalIqImg from '../../images/hospital-iq.png'
import summaryGraphImg from '../../images/summary-graph.png'
import graphImg from '../../images/graph.png'
import classnames from 'classnames'
import { PieChart } from 'react-minimal-pie-chart'
import Divider from '../../components/Divider'
// import { PieChart, Pie } from 'recharts'
export default function MTCPage1() {
  return (
    <div>
      <ReportHeader />
      <Divider />
      <ReportStatics />
      <MonitoringSummary />
      <DiagnosticAndPhysician />
      <Divider />
    </div>
  )
}

function ReportHeader() {
  return (
    <section className="flex justify-between items-center">
      <div className=" h-[93px] w-[342px]">
        {/* <div className="h-5 w-[220px] 2xl:h-[93px] 2xl:w-[342px]"> */}
        <img src={cardiacRhythmImg} layout="responsive" />
      </div>
      <div className="text-2xl  text-center ">
        <h1 className="text-[40px] font-bold mb-4">MCT Summary Report</h1>
        <p className="mb-2">Patient: Robert Downey</p>
        <p>Date: 01/10/2019</p>
      </div>
      {/* <div className="h-[149px] w-[207px] 2xl:h-[222] 2xl:w-[207px]"> */}
      <div className=" h-[222] w-[207px]">
        <img height={222} width={308} src={hospitalIqImg} />
      </div>
    </section>
  )
}

function ReportStatics() {
  const data = [
    { label: 'Gender', value: 'Male' },
    { label: 'DOB', value: '12/02/1985' },
    { label: 'Phone', value: '6600001081' },
    { label: 'Indication:', value: 'D-10-CM-150.20' },
  ]
  const boxStyle =
    'w-1/3 rounded-2xl border-[2px] h-full border-report-skyblue py-4 '
  const h1 = 'font-bold text-[26px] '
  return (
    <section className="  h-[400px] flex  gap-4 mt-6  text-2xl mb-6 ">
      {/* box 1 */}
      <div className={boxStyle}>
        <div className="px-9 mb-12 max-w-[320px] ">
          <h1 className={classnames(h1, 'mb-3')}>Ordering Physician :</h1>
          <p>Dr. William Smith</p>
          <p>7th Street, Haddows Road - 552 612 </p>
        </div>
        <div className=" bg-report-skyblue w-[95%] mx-auto h-[2px] " />
        <div className="px-9 mt-4 mb-2   max-w-[360px]">
          <h1 className={classnames(h1, 'mb-5')}>Interpreting Physician :</h1>
          <p>Dr. Robert Hickey</p>
          <p>5th Street, Frost Hills - 521 362 </p>
        </div>
      </div>
      {/* box 2 */}
      <div className={boxStyle}>
        <div className="px-9 mb-6  flex flex-col gap-[14px] ">
          {data.map((data, index) => (
            <div key={index} className="flex justify-between">
              <label>{data.label}</label>
              <label>{data.value}</label>
            </div>
          ))}
        </div>
        <div className=" bg-report-skyblue w-[95%] mx-auto h-[2px] " />

        <div className="px-9 mt-4 mb-[14px] flex  justify-between ">
          <div>
            <h1 className={classnames(h1, 'mb-4')}>Monitored Time</h1>
            <p>20d 20h 30m </p>
          </div>
          <div className="hidden 2xl:flex h-20 w-[.5px] bg-[#75C7D8]" />
          <div>
            <h1 className={classnames(h1, 'mb-4')}>Analysis Time</h1>
            <p> 20d 12h 41m </p>
          </div>
        </div>
        <p className="text-base px-9">01/30/2019 to 01/30/2019 11:00 am</p>
      </div>
      <div className={classnames(boxStyle, 'px-6 pb-4')}>
        <h1 className={h1}>Beat Summary</h1>
        <div className=" h-[90%] text-[4px] ">
          <SunburstChart />
        </div>
        {/* <img src={summaryGraphImg} /> */}
      </div>
    </section>
  )
}

function SunburstChart() {
  const data = [
    { title: 'Other ', value: 20, color: '#0C8281' },
    { title: ' Normal Sinus Rhythm ', value: 26.11, color: '#28A8E0' },
    { title: 'AFib/AFL ', value: 30, color: '#62BED8' },
    { title: 'SVE ', value: 5, color: '#564A9D' },
    { title: 'Pause/block  ', value: 0.01, color: '#010101' },
    { title: 'VE ', value: 0.79, color: '#F17383' },
    { title: 'Tachycardia  ', value: 2, color: '#00A79C' },
    { title: 'Bradycardia   ', value: 3, color: '#1D76BB' },
  ]

  return (
    <PieChart data={data} label={({ dataEntry }) => dataEntry.value + '%'} />
  )
}

function MonitoringSummary() {
  return (
    <div className="  border-[2px] rounded-2xl border-report-skyblue w-full min-h-[400px] overflow-hidden mb-4">
      <heading className="flex text-white text-4xl font-semibold items-center justify-center bg-report-skyblue h-16">
        Monitoring Summary
      </heading>
      <section className="h-[100px] flex border-b-[2px] border-b-report-skyblue">
        <div className="w-1/4 flex flex-col items-center justify-center border-r-report-skyblue border-r-[2px] h-full ">
          <p className="text-2xl">Analysis Time: 20d 12h 41m</p>
          <p>(Monitored time - Artifact and off device)</p>
        </div>
        <div className="w-1/4 flex flex-col items-center justify-center border-r-report-skyblue border-r-[2px] h-full ">
          <p className="text-2xl">Total Beats: 1,506,000</p>
        </div>
        <div className="w-1/4 flex flex-col items-center justify-center border-r-report-skyblue border-r-[2px] h-full ">
          <p className="text-2xl px-2">Physician Notification: 5 Events</p>
        </div>
        <div className="w-1/4 flex flex-col items-center justify-center  h-full ">
          <p className="text-2xl">Patient Reported: 2 Events</p>
        </div>
      </section>
      {/*  */}
      <div className="flex">
        <MonitoringSummaryLeftData />
        <Graphs />
      </div>
    </div>
  )
}

function MonitoringSummaryLeftData() {
  const atrialData = [
    {
      label: 'Fastest rate',
      value: '157 BPM at 12:49 d6',
    },
    {
      label: 'Average',
      value: '86 BPM',
    },
    {
      label: 'Lowest rate',
      value: 'PM at 01:32 d10',
    },
    {
      label: 'Longest episode',
      value: 'at  20:45 d4',
    },
  ]
  return (
    <div className="w-[45%] px-7 pt-6 pb-5 flex flex-col gap-5">
      <SummaryData
        label="Atrial Fibrillation / Flutter "
        value="36.69%"
        data={atrialData}
      />
      <SummaryData
        label="Atrial Fibrillation / Flutter "
        value="36.69%"
        data={atrialData}
      />
      <SummaryData
        label="Atrial Fibrillation / Flutter "
        value="36.69%"
        data={atrialData}
      />
      <SummaryData
        label="Atrial Fibrillation / Flutter "
        value="36.69%"
        data={atrialData}
      />
      <SummaryData
        label="Atrial Fibrillation / Flutter "
        value="36.69%"
        data={atrialData}
      />
    </div>
  )
}
function SummaryData({ label, value, data }) {
  return (
    <dvi>
      <div className="flex justify-between font-semibold text-2xl mb-4">
        <h1>{label}</h1>
        <h1>36.69%</h1>
      </div>
      <div className="text-lg flex flex-col gap-[6px]">
        {data.map((data, index) => (
          <div key={index} className="flex justify-between">
            <label>{data.label}</label>
            <value>{data.value}</value>
          </div>
        ))}
      </div>
    </dvi>
  )
}

function Graphs() {
  return (
    <div className="w-[55%] min-[100px]  flex flex-col gap-14 px-4 py-11">
      <GraphAndTitle time="PM at 01:21 am on 01/10" pageNo={14} />
      <GraphAndTitle time="PM at 07:28 pm on 01/11" pageNo={4} />
      <GraphAndTitle time="PM at 07:56 pm on 01/10 (Longest)" pageNo={5} />
      <GraphAndTitle time="PM at 07:56 pm on 01/10 (Fastest)" pageNo={9} />
      <GraphAndTitle time="PM at 01:26 pm on 01/14 (Fastest)" pageNo={7} />
      <GraphAndTitle time="PM at 19:39 pm on 01/12" pageNo={13} />
    </div>
  )
}

function GraphAndTitle({ time, pageNo }) {
  return (
    <div>
      <div className="flex justify-between mb-4">
        <p>{time}</p>
        <p>
          <span>Page</span> <span>{pageNo}</span>
        </p>
      </div>
      <img src={graphImg} layout="responsive" />
    </div>
  )
}

function DiagnosticAndPhysician() {
  return (
    <div className="  border-[2px] rounded-2xl border-report-skyblue w-full h-44  overflow-hidden mb-[22px] flex justify-between">
      <section className="w-1/2 px-8 py-6 flex flex-col justify-between">
        <div>
          <h1 className="text-2xl font-semibold mb-2">Diagnostic Notes</h1>
          <p className="text-lg">Rhythm with AFib / AFL</p>
        </div>
        <p>
          <span className="font-bold ">Reported by:</span>
          <span> Catherine Cook, CCT – CCI (USA)</span>
        </p>
      </section>
      <div className="h-full w-[.5px] bg-[#231F20]" />
      <section className=" w-1/2 px-8 py-6 flex flex-col justify-between">
        <div>
          <h1 className="text-2xl font-semibold mb-2">
            Physician’s Interpretation:
          </h1>
        </div>
        <p className="text-xl flex items-end gap-5">
          <span>Signature:</span>
          <div className="w-[325px] h-[1px] bg-[#231F20]" />
        </p>
      </section>
    </div>
  )
}
