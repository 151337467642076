//import PatientReportLayout from '../PatientReportLayout'
import classnames from "classnames";
// data
import {
  patientInformation,
  serviceManagement,
  studySetting,
  referringPhysician,
} from "./data";
export default function PatientDemographicsPage2() {
  return (
    //<PatientReportLayout>
    <div className="flex gap-4 w-full ">
      <Left />
      <Right />
    </div>
    //</PatientReportLayout>
  );
}

function Layout({ title, children }) {
  return (
    <div
      className="bg-white rounded-[10px] shadow-[0px_15px_30px_rgba(179,179,179,0.06)]
    w-full min-h-[141px]
    "
    >
      <div className="px-[68px] h-[51px] border-b-[1px] border-[#E9E9E9] font-bold flex items-center">
        {title}
      </div>

      {children}
    </div>
  );
}

function LabelAndValue({ className, data }) {
  return (
    <>
      <div
        className={classnames(
          className,
          "px-2 2xl:px-16 flex flex-wrap gap-y-4 gap-x-8 py-8"
        )}
      >
        {data.map(({ label, value }, index) => (
          <div
            key={index}
            className="flex w-[46%]  justify-between items-start"
          >
            <label className="font-semibold whitespace-nowrap">{label} :</label>
            <value className="">{value} </value>
          </div>
        ))}
      </div>
    </>
  );
}

function Left() {
  return (
    <div className="flex flex-col gap-[9px] w-1/2">
      <Layout title="Patient Information">
        <LabelAndValue data={patientInformation} />
      </Layout>
      <Layout title="Service Management">
        <LabelAndValue data={serviceManagement} />
      </Layout>
      <Layout title="Diagnostic Information"></Layout>
      <Layout title="Study Logs"></Layout>
      <Layout title="Physician Amendment notes"></Layout>
    </div>
  );
}
function Right() {
  return (
    <div className="flex flex-col gap-[9px] w-1/2">
      <Layout title="Doctor Information">
        <h1 className="font-semibold pl-[68px] mt-4">Referring physician</h1>
        <LabelAndValue data={referringPhysician} />
        <h1 className="font-semibold pl-[68px]">Referring physician</h1>
        <LabelAndValue data={referringPhysician} />
      </Layout>
      <Layout title="Study Setting">
        <LabelAndValue data={studySetting} />
      </Layout>
      <Layout title="Study Notes"></Layout>
      <Layout title="Study History"></Layout>
    </div>
  );
}
