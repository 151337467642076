export const patientInformation = [{
        label: 'Name',
        value: 'Chris Johnson',
    },
    {
        label: 'Primary Contact Number',
        value: ' 37859 38392',
    },
    {
        label: 'Date of Birth ',
        value: ' 12-02-1978',
    },
    {
        label: 'Secondary Contact Number',
        value: '98765 04482',
    },
    {
        label: 'Age',
        value: 45,
    },
    {
        label: 'Address',
        value: 'No.987, Texas, USA',
    },
    {
        label: 'Gender',
        value: 'Male',
    },
]

export const serviceManagement = [{
        label: 'Status',
        value: 'Ongoing',
    },
    {
        label: 'Patient Return Visit',
        value: ' 08/03/2023',
    },
    {
        label: 'Start date and time ',
        value: ' 05/02/2023 00:05',
    },
    {
        label: 'Device ID',
        value: '28392',
    },
    {
        label: 'End date and time ',
        value: ' 05/02/2023 00:05',
    },
    {
        label: 'Low Cell Signal ',
        value: ' No',
    },
    {
        label: 'Study Type ',
        value: ' Holter',
    },
    {
        label: 'RMA ',
        value: ' No',
    },
]

export const studySetting = [{
        label: 'Pre-event Time',
        value: '30 sec',
    },
    {
        label: 'Post-event Time',
        value: '30 sec',
    },
    {
        label: 'Tachycardia Threshold',
        value: '120 bpm',
    },
    {
        label: 'Bradycardia Threshold',
        value: '120 bpm',
    },
    {
        label: 'Pause Level',
        value: '2.5 sec',
    },
    {
        label: 'Diagnosis Lead',
        value: '2',
    },
    {
        label: 'Pause Detection',
        value: 'On',
    },
    {
        label: 'AFib Detection',
        value: 'On',
    },
    {
        label: 'Duration',
        value: '30d 00h 00m',
    },
]

export const referringPhysician = [{
        label: 'Name',
        value: 'Dr. Amanda Johnson',
    },
    {
        label: 'Hospital',
        value: 'Health Rogh Clinic',
    },
    {
        label: 'Day phone',
        value: '84897 49992',
    },
    {
        label: 'Address',
        value: 'No.987, Texas, USA',
    },
    {
        label: 'After hours phone',
        value: ' 98897 49992',
    },
    {
        label: 'Fax no',
        value: '2846827',
    },
    {
        label: 'Email',
        value: 'amandahj@gmail.com',
    },
]