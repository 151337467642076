//import Image from 'next/image'
import MinimumSinusRateImg from './images/minimum-sinus-rate.png'
import FirstDegreeAVBlockImg from './images/FirstDegreeAVBlock.png'
import SinusArrhythmia from './images/SinusArrhythmia.png'
import SinusRhythmImg from './images/SinusRhythm.png'
import AtrialFibrillationImg from './images/AtrialFibrillation.png'
import DayAndNightBurdenImg from './images/DayAndNightBurden.png'
import PausesImg from './images/Pauses.png'
import LongestPauseImg from './images/LongestPause.png'
import Container from './../Container'
import classnames from 'classnames'
import { graphsStore } from '../store'
import { IoClose } from 'react-icons/io5'

function GraphsImgContainer({ checked, title, src, height, width, toggleFun }) {
  return (
    <div className=" ">
      {title && <div className="text-2xl font-bold mb-2">{title}</div>}
      <div className="flex items-start ">
        <input
          type="checkbox"
          checked={checked}
          className="mr-4 mt-4 cursor-pointer"
          id=""
          onClick={toggleFun}
        />
        <div
          className={classnames('relative')}
          style={{ height: height, width: width }}
        >
          <img src={src} />
        </div>
      </div>
    </div>
  )
}

export default function SummaryECG() {
  const {
    minimumSinusRateState,
    toggleMinimumSinusRate,
    firstDegreeAVBlockState,
    toggleFirstDegreeAVBlock,
    sinusArrhythmiaState,
    toggleSinusArrhythmia,
    sinusRhythmState,
    toggleSinusRhythm,
    atrialFibrillationState,
    toggleAtrialFibrillation,
    dayAndNightBurdenState,
    toggleDayAndNightBurden,
    pausesState,
    togglePauses,
    longestPauseState,
    toggleLongestPause,
  } = graphsStore()
  console.log(minimumSinusRateState)
  return (
    <Container className="pb-4">
      <h1 className="ml-4 mt-[58px]  mb-[30px] text-xl font-bold  font-roboto">
        Summary ECG
      </h1>
      <GraphsImgContainer
        checked={minimumSinusRateState}
        toggleFun={toggleMinimumSinusRate}
        src={MinimumSinusRateImg}
      />
      <GraphsImgContainer
        checked={firstDegreeAVBlockState}
        toggleFun={toggleFirstDegreeAVBlock}
        src={FirstDegreeAVBlockImg}
      />
      <GraphsImgContainer
        checked={sinusArrhythmiaState}
        toggleFun={toggleSinusArrhythmia}
        src={SinusArrhythmia}
      />
      <GraphsImgContainer
        checked={sinusRhythmState}
        toggleFun={toggleSinusRhythm}
        src={SinusRhythmImg}
      />
      <GraphsImgContainer
        checked={atrialFibrillationState}
        toggleFun={toggleAtrialFibrillation}
        src={AtrialFibrillationImg}
      />
      <GraphsImgContainer
        checked={dayAndNightBurdenState}
        toggleFun={toggleDayAndNightBurden}
        src={DayAndNightBurdenImg}
      />
      <GraphsImgContainer
        checked={pausesState}
        toggleFun={togglePauses}
        src={PausesImg}
      />
      <GraphsImgContainer
        checked={longestPauseState}
        toggleFun={toggleLongestPause}
        src={LongestPauseImg}
      />
    </Container>
  )
}

function OutputGraphContainer({ closeFun, title, src, className }) {
  return (
    <div
      className={classnames(
        className,
        'relative border-[1px] border-[#E8E8E8]   shadow-[0px_20px_40p_rgba(121,121,121,0.06)] bg-white rounded-[20px]  w-full px-12 py-11 ',
      )}
    >
      {title && <div className="text-2xl font-bold mb-2">{title}</div>}
      <img src={src} />
      <IoClose
        onClick={closeFun}
        className="absolute top-8  right-8 text-3xl cursor-pointer"
      />
    </div>
  )
}

export function OutputGraphs() {
  const {
    minimumSinusRateState,
    toggleMinimumSinusRate,
    firstDegreeAVBlockState,
    toggleFirstDegreeAVBlock,
    sinusArrhythmiaState,
    toggleSinusArrhythmia,
    sinusRhythmState,
    toggleSinusRhythm,
    atrialFibrillationState,
    toggleAtrialFibrillation,
    dayAndNightBurdenState,
    toggleDayAndNightBurden,
    pausesState,
    togglePauses,
    longestPauseState,
    toggleLongestPause,
  } = graphsStore()
  return (
    <div className="w-full flex flex-col gap-4 mt-4">
      {minimumSinusRateState && (
        <OutputGraphContainer
          closeFun={toggleMinimumSinusRate}
          src={MinimumSinusRateImg}
        />
      )}
      {firstDegreeAVBlockState && (
        <OutputGraphContainer
          closeFun={toggleFirstDegreeAVBlock}
          src={FirstDegreeAVBlockImg}
        />
      )}
      {sinusArrhythmiaState && (
        <OutputGraphContainer
          closeFun={toggleSinusArrhythmia}
          src={SinusArrhythmia}
        />
      )}
      {sinusRhythmState && (
        <OutputGraphContainer
          title="Sinus Rhythm"
          closeFun={toggleSinusRhythm}
          src={SinusRhythmImg}
        />
      )}
      {atrialFibrillationState && (
        <OutputGraphContainer
          title="Atrial Fibrillation"
          closeFun={toggleAtrialFibrillation}
          src={AtrialFibrillationImg}
        />
      )}
      {dayAndNightBurdenState && (
        <OutputGraphContainer
          title="AF/AFL Rate Summary"
          closeFun={toggleDayAndNightBurden}
          src={DayAndNightBurdenImg}
        />
      )}

      {pausesState && (
        <OutputGraphContainer
          title="Pauses"
          closeFun={togglePauses}
          src={PausesImg}
        />
      )}
      {longestPauseState && (
        <OutputGraphContainer
          title="Longest Pause"
          closeFun={toggleLongestPause}
          src={LongestPauseImg}
        />
      )}
    </div>
  )
}
