import React, { useEffect, useState } from "react";
import * as d3 from "d3";
type EctopyProps = {
  AllEctopies: any;
  TemplateVType: any;
  TemplateClass: any;
  selectedDataSetAll: any;
  beatView: any;
  isEDFData: any;
  mmvValueSplitView: any;
  GraphColors: {
    DefaultLineColor: string;
    VELineColor: string;
    SVELineColor: string;
  };
  getBeatDataInRangeEDF: Function;
  ViewMeasurement: Function;
};
function Ectopy(props: EctopyProps) {
  useEffect(() => {
    if (props.AllEctopies.length > 0) {
      loadEctopyGraphInBox(props.TemplateVType, props.TemplateClass);
    }
  }, [
    props.AllEctopies,
    props.TemplateVType,
    props.TemplateClass,
    props.beatView,
  ]);

  function loadEctopyGraphInBox(p_VType: number, p_Class: number) {
    let l_AllEctopies = props.AllEctopies.filter(
      (o: any) => o.VType === p_VType && o.Class === p_Class
    );

    for (let i = 0; i < l_AllEctopies.length; i++) {
      let boxSizeWidth = 100;
      let boxSizeHeight = 100;
      let boxNumber = 95;

      let boxStartTime = 0.4;
      let boxEndTime = 0.6;

      if (props.beatView === 1) {
        boxSizeWidth = 75;
        boxSizeHeight = 75;
        boxNumber = 70;
      } else if (props.beatView === 2) {
        boxSizeWidth = 115;
        boxSizeHeight = 115;
        boxNumber = 110;
      } else if (props.beatView === 3) {
        boxSizeWidth = 240;
        boxSizeHeight = 240;
        boxNumber = 235;
      } else if (props.beatView === 4) {
        boxSizeWidth = 410;
        boxNumber = 395;
        boxStartTime = 0.4;
        boxEndTime = 0.6;
      } else if (props.beatView === 5) {
        boxSizeWidth = 300;
        boxNumber = 295;
        boxStartTime = 1.1;
        boxEndTime = 1.2;
      } else if (props.beatView === 6) {
        boxSizeWidth = 500;
        boxNumber = 495;
        boxStartTime = 2.1;
        boxEndTime = 2.2;
      }

      let boxGraph = d3
        .select("#" + l_AllEctopies[i].EctopyID)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden");

      // These will be the start and End time of the data in the box
      let startTime = 0;
      let endTime = 1;

      let l_startTime = l_AllEctopies[i].Time - boxStartTime;
      let l_EndTime = l_AllEctopies[i].Time + boxEndTime;
      let Range;
      //TODO:This else should use beat data without EDF - or remove if we only have EDF data
      if (props.isEDFData === true) {
        Range = props.getBeatDataInRangeEDF(l_startTime, l_EndTime, 0) as any;
      } else {
        Range = props.getBeatDataInRangeEDF(l_startTime, l_EndTime, 0) as any;
      }

      if (Range && Range !== null && Range !== "" && Range.length > 0) {
        startTime = Range[0][0];
        endTime = Range[Range.length - 1][0];

        let currentColor = props.GraphColors.DefaultLineColor;

        let boxStartTimeDifference = l_AllEctopies[i].Time - 0.2;
        let boxEndTimeDifference = l_AllEctopies[i].Time + 0.2;

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([15, boxSizeWidth / 2]);

        let localYRange = 30000 / props.mmvValueSplitView;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([boxSizeHeight, 0]);

        let uniqueID = l_AllEctopies[i].EctopyID;
        d3.selectAll("." + uniqueID).remove();

        if (Range.length > 0) {
          for (let j = 1; j < Range.length; j++) {
            if (
              Range[j - 1][0] > boxStartTimeDifference &&
              Range[j - 1][0] < boxEndTimeDifference
            ) {
              if (props.TemplateVType === 1) {
                currentColor = props.GraphColors.VELineColor;
              } else {
                currentColor = props.GraphColors.SVELineColor;
              }
            } else {
              currentColor = props.GraphColors.DefaultLineColor;
            }
            boxGraph
              .append("line")
              .attr("class", uniqueID)
              .style("stroke", currentColor)
              .attr("x1", xScaleBox(Range[j - 1][0]))
              .attr("y1", yScaleBox(Range[j - 1][1]))
              .attr("x2", xScaleBox(Range[j][0]))
              .attr("y2", yScaleBox(Range[j][1]));
          }
        }

        boxGraph
          .append("text")
          .attr("class", uniqueID)
          .text(i + 1)
          .attr("fill", "black")
          .attr("font-size", "12px")
          .attr("font-weight", "bold")
          .attr("text-anchor", "end")
          .attr("x", boxNumber)
          .attr("y", 55);
      }
    }
  }

  function getBeatDataInRange(StartTime: any, EndTime: any) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < props.selectedDataSetAll.length; i++) {
      for (let j = 0; j < props.selectedDataSetAll[i].data.length; j++) {
        var StartTimeMin = Math.abs(
          StartTime - props.selectedDataSetAll[i].data[j][0]
        );
        if (StartTimeMin < StartTimeMinDiff) {
          StartTimeMinDiff = StartTimeMin;
          StartTimeNearestBeat = props.selectedDataSetAll[i].data[j][0];
          StartTimeNearestBeatIndex = j;
          StartMainIndex = i;
        }

        var EndTimeMin = Math.abs(
          EndTime - props.selectedDataSetAll[i].data[j][0]
        );
        if (EndTimeMin < EndTimeMinDiff) {
          EndTimeMinDiff = EndTimeMin;
          EndTimeNearestBeat = props.selectedDataSetAll[i].data[j][0];
          EndTimeNearestBeatIndex = j;
          EndMainIndex = i;
        }
      }
    }
    let ReurnArray = [];
    if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex === EndMainIndex
    ) {
      ReurnArray = props.selectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        EndTimeNearestBeatIndex + 1
      );
    } else if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex !== EndMainIndex
    ) {
      let StartArray = props.selectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        props.selectedDataSetAll[StartMainIndex].data.length
      );
      let EndArray = props.selectedDataSetAll[EndMainIndex].data.slice(
        0,
        EndTimeNearestBeatIndex + 1
      );
      ReurnArray = StartArray.concat(EndArray);
    }

    return ReurnArray;
  }

  return (
    <div className="template--classes">
      {props.AllEctopies.map((Item: any, index: number) => (
        <React.Fragment key={index}>
          <>
            {Item.VType === props.TemplateVType &&
              Item.Class === props.TemplateClass && (
                <div
                  className="template--class--item"
                  onClick={() => props.ViewMeasurement(Item.Time, index)}
                >
                  <svg id={Item.EctopyID}></svg>
                </div>
              )}
          </>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Ectopy;
