import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../Context/Context";

function Login() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    var token = AppContext.GetCookie(AppContext.Token);
    if (token && token !== "") {
      navigate("/patient");
    }
  }, []);

  function LoginUser() {
    if (userName === AppContext.UserName && password === AppContext.Password) {
      AppContext.SetCookie(
        AppContext.Token,
        AppContext.GenerateUniqueGUID(),
        120
      );
      navigate("/patient");
    } else {
      alert("Please enter valid credentials");
    }
  }

  function onEnterPress(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      LoginUser();
    }
  }

  return (
    <>
      <div className="login--main">
        <div className="login--left">
          <div className="login-left--inn">
            <div className="login--logo">
              <img
                src="/images/logo.png"
                className="holter-logo"
                width="20px"
                height="20px"
                alt="logo"
              />
            </div>
            <div className="login-text">Sign in to your account</div>
            <div className="login-input">
              <span className="label-txt">Email</span>
              <input
                placeholder="xyz@gmail.com"
                value={userName}
                onChange={(e: any) => setUserName(e.target.value)}
              />
            </div>
            <div className="login-input">
              <span className="label-txt">Password</span>
              <input
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyDown={onEnterPress}
              />
            </div>
            <div className="btn--login">
              <button onClick={() => LoginUser()}>Login</button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="ti-logo">
                <span>A Product of</span>
                <img
                  src="/images/ti-logo.png"
                  width="20px"
                  height="20px"
                  alt="logo"
                />
              </div>
              <span>©️ Beatly.ai All rights reserved. V0.92.2</span>
            </div>
          </div>
        </div>
        <div className="login--right">
          <img
            src="/images/e-banner.png"
            width="20px"
            height="20px"
            alt="Banner"
          />
        </div>
      </div>
    </>
  );
}

export default Login;
