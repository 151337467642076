//import PatientReportLayout from '../PatientReportLayout'
import classnames from "classnames";
import { heartRateData, othersData } from "./data";
import ToggleOptions from "./Graphs/ToggleOptions";
import { OutputGraphs } from "./Graphs/Graphs";
import Box from "./../Box";

export default function SummaryPage2() {
  const row1BoxStyle =
    "border-[1px] border-[#E8E8E8]  w-1/3 shadow-[0px_20px_40p_rgba(121,121,121,0.06)] bg-white rounded-[20px] h-full ";

  return (
    // <PatientReportLayout>
    <>
      <ToggleOptions />
      {/* HeartRate VE SVE*/}
      <div className="mt-3 flex gap-4  h-[512px]">
        <FirstRowData />
        <FirstRowData title="VE" />
        <FirstRowData title="SVE" />
      </div>
      {/* Event Variability Others  */}
      <div className="mt-3 flex gap-4 min-h-[432px]">
        <FirstRowData title="Event" />
        <FirstRowData title="Heart Rate Variability" />
        <div className="w-1/3 flex flex-col gap-[5px]">
          <OtherBox />
          <STmV />
        </div>
      </div>

      <BurdenPulseIndication />

      {/* Technician Physician  (Comments) */}
      <div className="mt-3 flex gap-4 h-[365px]">
        <Box width="w-1/2" pb="0" pt="0" px="0">
          <h2 className="h-[74px] border-b flex  items-center text-xl font-bold px-[74px] edit-icon ">
            Technician Comments
            <img src="/images/edit.png" width="20" height="20" />
          </h2>
          <div className=" px-[74px] pt-14 pb-16 text-[#616161]  ">
            <p> 1. Atrial Fibrillation is noted.</p>
            <p> 2. Predominant rhythm was sinus.</p>
            <p> 3. Min HR of 68 bpm Max HR of 98 bpm and Avg HR of 54 bpm.</p>
            <p> 4. Supraventricular ectopics were noted.</p>
            <p> 5. Ventricular ectopics were noted.</p>
            <div className="mt-8"> KM.CCT/MCT</div>
          </div>
        </Box>
        <Box width="w-1/2" pb="0" pt="0" px="0">
          <h2 className="h-[74px] border-b flex  items-center text-xl font-bold px-[74px]  ">
            Physician Comments
          </h2>
        </Box>
      </div>
      {/*  */}
      <OutputGraphs />
    </>
    // </PatientReportLayout>
  );
}

function FirstRowData({ data = heartRateData, title = "Heart Rate Data" }) {
  return (
    <Box>
      <h3 className="text-xl font-bold mb-[30px]">{title}</h3>

      <div className="flex flex-col gap-2  ">
        {data.map((data, index) => (
          <div
            key={index}
            className="flex justify-between text-lg text-[#424242]"
          >
            <div className="">{data.label}:</div>
            <div className="">{data.value}</div>
          </div>
        ))}
      </div>
    </Box>
  );
}

function OtherBox() {
  return (
    <Box height="h-1/2" width="w-full">
      <h3 className="text-xl font-bold mb-[30px]">Others</h3>
      <div className="flex flex-col gap-2  ">
        {othersData.map((data, index) => (
          <div
            key={index}
            className="flex justify-between text-lg text-[#424242]"
          >
            <div className="">{data.label}:</div>
            <div className="">{data.value}</div>
          </div>
        ))}
      </div>
    </Box>
  );
}

function STmV() {
  const tdStyle = "py-[18.5px] flex justify-between w-1/3 pr-3";
  const stData = [
    { ch: "CH1", Depression: 2, Elevation: 2 },
    { ch: "CH2", Depression: 1, Elevation: 0 },
    { ch: "CH3", Depression: 0, Elevation: 1 },
  ];

  return (
    <Box height="h-1/2" width="w-full" px="0" pb="0" pt="0">
      <h3 className="text-xl font-bold pb-[30px] pl-[53px] pt-4">ST(mV)</h3>
      <div className="flex w-full justify-between   border-t border-b px-7   h-12    ">
        {stData.map((data, i) => (
          <th
            className={classnames(
              "ml-2 w-1/3 h-full flex items-center   text-xl font-semibold text-[#424242]",
              { "border-r": !(i === 2) }
            )}
          >
            {data.ch}
          </th>
        ))}
      </div>
      <div className="flex w-full  justify-between pr-7  pl-9  ">
        {stData.map((data, i) => (
          <div key={i} className="flex flex-col ">
            {/* border-2 */}
            <td className={classnames(tdStyle, { "": !(i === 2) })}>
              <span>Depression</span>
              <span>{data.Depression} </span>
            </td>
            <td className={classnames(tdStyle, { "": !(i === 2) })}>
              <span>Elevation</span>
              <span>{data.Elevation}</span>
            </td>
          </div>
        ))}
      </div>
    </Box>
  );
}

function BurdenPulseIndication() {
  const BPI_Data = [
    {
      label: "Afib VT ",
      value: "0000",
    },
    {
      label: "Heart rate turbulence",
      value: "0000",
    },
    {
      label: "Signal avg ",
      value: "0000",
    },
    {
      label: "ECG ",
      value: "0000",
    },
  ];
  return (
    <Box height="min-h-[209px]" width="w-full" className="mt-3">
      <h2 className="text-[#424242] text-lg mb-8 font-bold ">
        Burden Pulse Indication
      </h2>
      <div className="flex flex-wrap  gap-y-4 gap-x-16 max-w-6xl   ">
        {BPI_Data.map((data, i) => (
          <div key={i} className=" w-[500px] flex justify-between h-6 ">
            <label>{data.label}</label>
            <value> {data.value}</value>
          </div>
        ))}
      </div>
    </Box>
  );
}
