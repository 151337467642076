import React, { useState, useRef, useEffect } from "react";
type GridSelectionProps = {
  getSelectedRowsAndColumns: any;
};
function GridSelection(props: GridSelectionProps) {
  const [SelectedValues, setSelectedValues] = useState({
    SelectedRowCount: 0,
    SelectedColumnsCount: 0,
  });

  const [showGridSelector, setShowGridSelector] = useState(false);

  const [rows, setRows] = useState([
    { RowCount: 1 },
    { RowCount: 2 },
    { RowCount: 3 },
    { RowCount: 4 },
  ]);
  const [columns, setColumns] = useState([
    { ColumnCount: 1 },
    { ColumnCount: 2 },
    { ColumnCount: 3 },
    { ColumnCount: 4 },
    { ColumnCount: 5 },
    { ColumnCount: 6 },
    { ColumnCount: 7 },
  ]);

  function setSelectedRowsAndColumns(rows: any, columns: any) {
    let object = {} as any;
    object.Rows = rows;
    object.Columns = columns;
    props.getSelectedRowsAndColumns(object);
    setShowGridSelector(false);
  }

  function applyClassOnMouseOver(rows: any, columns: any) {
    SelectedValues.SelectedRowCount = rows;
    SelectedValues.SelectedColumnsCount = columns;
    for (let i = 1; i <= SelectedValues.SelectedRowCount; i++) {
      for (let j = 1; j <= SelectedValues.SelectedColumnsCount; j++) {
        let id = i + "" + j;
        let elm: any = document.getElementById(id);
        elm.classList.add("hover");
      }
    }
  }

  function mouseOut() {
    const boxes = document.querySelectorAll("span");
    boxes.forEach((box) => {
      box.classList.remove("hover");
    });
  }

  return (
    <div className="rows-cols">
      <img
        src="/images/e14.svg"
        alt="Grid"
        width="20"
        height="20"
        onClick={() => setShowGridSelector(!showGridSelector)}
      />
      {showGridSelector && (
        <div className="select-boxes">
          {rows.map((rowsItem: any, rowsIndex: any) => (
            <div className="row-over row-1" key={rowsIndex}>
              {columns.map((columnsItem: any, columnsIndex: any) => (
                <span
                key={rowsItem.RowCount + "" + columnsItem.ColumnCount}
                  id={rowsItem.RowCount + "" + columnsItem.ColumnCount}
                  onMouseLeave={mouseOut}
                  onMouseOver={() =>
                    applyClassOnMouseOver(
                      rowsItem.RowCount,
                      columnsItem.ColumnCount
                    )
                  }
                  onClick={() =>
                    setSelectedRowsAndColumns(
                      rowsItem.RowCount,
                      columnsItem.ColumnCount
                    )
                  }
                >
                  <p></p>
                </span>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GridSelection;

//--- Instruction ---//
//--- Add following code into jsx/html --- <GridSelectiongetSelectedRowsAndColumns={getSelectedRowsAndColumns} />
//--- Add following function into ts
//function getSelectedRowsAndColumns(childData: any) {
//    let rows = childData.Rows;
//   let columns = childData.Columns;
//  }
