import Divider from '../../components/Divider'
import ReportHeader from '../../components/ReportHeader'
//
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function MCT_DataTrends() {
  const options = {
    title: {
      text: 'My chart',
    },
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  }
  const options2 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
    },
    title: {
      text: 'Wind speed during two days',
      align: 'left',
    },
    subtitle: {
      text: '13th & 14th of June, 2022 at two locations in Vik i Sogn, Norway',
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
      },
    },
    yAxis: {
      title: {
        text: 'Wind speed (m/s)',
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      plotBands: [
        {
          // Light air
          from: 0.3,
          to: 1.5,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Light air',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Light breeze
          from: 1.5,
          to: 3.3,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Light breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Gentle breeze
          from: 3.3,
          to: 5.5,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Gentle breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Moderate breeze
          from: 5.5,
          to: 8,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Moderate breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Fresh breeze
          from: 8,
          to: 11,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Fresh breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // Strong breeze
          from: 11,
          to: 14,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Strong breeze',
            style: {
              color: '#606060',
            },
          },
        },
        {
          // High wind
          from: 14,
          to: 15,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'High wind',
            style: {
              color: '#606060',
            },
          },
        },
      ],
    },
    tooltip: {
      valueSuffix: ' m/s',
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
        pointInterval: 3600000, // one hour
        pointStart: Date.UTC(2022, 5, 13, 0, 0, 0),
      },
    },
    series: [
      {
        name: 'Hestavollane',
        data: [
          4.5,
          5.1,
          4.4,
          3.7,
          4.2,
          3.7,
          4.3,
          4,
          5,
          4.9,
          4.8,
          4.6,
          3.9,
          3.8,
          2.7,
          3.1,
          2.6,
          3.3,
          3.8,
          4.1,
          1,
          1.9,
          3.2,
          3.8,
          4.2,
        ],
      },
      {
        name: 'Vik',
        data: [
          0.1,
          0.1,
          0.1,
          0.2,
          0.4,
          0.4,
          0.3,
          0.4,
          0.1,
          0,
          0.2,
          0.3,
          0,
          0,
          0,
          0,
          0,
          0.1,
          0.1,
          0.1,
          0,
          0.1,
          0,
          0,
          0,
        ],
      },
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px',
      },
    },
  }

  return (
    <div className="min-h-screen">
      <ReportHeader
        boxInfo="DataTrends"
        startDate=" 01/10/2019 "
        patientInfo="Robert Downey/M/12/02/1985    "
      />
      <div>
        {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}

        <style>{`.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
         `}</style>
        <HighchartsReact highcharts={Highcharts} options={options2} />
      </div>
      {/*  */}
      <div></div>

      <Divider />
    </div>
  )
}
